const ColoredLine = () => (
    <hr style={{
        color: '#000000',
        backgroundColor: '#000000',
        height: '1px',
        width: '100%'
    }} />
);

export default ColoredLine;
